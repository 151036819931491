import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./Elite.css";
import {
  MainContainer,
  OutterContainer,
  InnerContainer,
  InnerContent,
} from "../../../components/layout/container/Container";
import { EliteData } from "../data/EliteData";
import photo1 from "./assets/photo1.jpg";
import photo2 from "./assets/photo2.jpg";
import photo3 from "./assets/photo3.jpg";

const colorGreen = "#5dbc0c";

const EliteProgram = () => {
  const [data, setData] = useState([]);
  const [phase, setPhase] = useState(0);
  const [day, setDay] = useState("");
  const [time, setTime] = useState("");

  const photos = [photo1, photo2, photo3];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setData(EliteData);
    return handleDefaultParams();
  }, []);

  useEffect(() => {
    const paramPhase = searchParams.get("phase");
    const paramDay = searchParams.get("day");
    const paramTime = searchParams.get("time");

    setPhase(paramPhase);
    setDay(paramDay);
    return setTime(paramTime);
  }, [searchParams]);

  const getDayFromDate = () => {
    const date = new Date();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const day = daysOfWeek[date.getDay()];

    return day;
    // return setDay(day);
  };

  const getTimeOfDay = () => {
    const datetime = new Date();
    const hours = datetime.getHours();
    const timeOfDay = hours < 12 ? "Morning" : "Afternoon";
    return timeOfDay;
  };

  const handleDefaultParams = () => {
    return setSearchParams({
      phase: 1,
      day: getDayFromDate(),
      time: getTimeOfDay(),
    });
  };

  useEffect(() => handleSlideShow(), [photos.length]);

  const handleSlideShow = () => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
        setFadeIn(true);
      }, 2000);
    }, 20000);
    return () => clearInterval(interval);
  };

  return (
    <MainContainer>
      <div className="elite-overlay">
        <img
          className="elite-overlay__image"
          src={photos[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          style={{
            opacity: fadeIn ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        />
      </div>

      <OutterContainer>
        <InnerContainer>
          <InnerContent className="elite-program__inner-content">
            <span className="elite-program__heading">
              10 Week Pre-Season Program
            </span>

            <br />
            <br />

            <p>
              This program is designed for individuals with an intermediate to
              advanced level of strength and fitness training experience.
            </p>

            <span className="elite-program__select-label">Select Phase</span>

            <div className="elite-program__buttons elite-program__buttons--phases">
              <button
                className="elite-program__button elite-program__button--phases"
                onClick={() =>
                  setSearchParams({
                    phase: 1,
                    day: getDayFromDate(),
                    time: getTimeOfDay(),
                  })
                }
                style={{ background: parseInt(phase) === 1 ? colorGreen : "" }}
              >
                Phase 1: 6 Weeks
              </button>
              <button
                className="elite-program__button elite-program__button--phases"
                onClick={() =>
                  setSearchParams({
                    phase: 2,
                    day: getDayFromDate(),
                    time: getTimeOfDay(),
                  })
                }
                style={{ background: parseInt(phase) === 2 ? colorGreen : "" }}
              >
                Phase 2: 4 Weeks
              </button>
            </div>

            {searchParams.get("phase") && (
              <>
                <span className="elite-program__select-label">Select Day</span>
                <div className="elite-program__buttons elite-program__buttons--days">
                  <button
                    className="elite-program__button elite-program__button--days"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: e.target.innerText,
                        time: getTimeOfDay(),
                      })
                    }
                    style={{
                      background: day === "Monday" ? colorGreen : "",
                    }}
                  >
                    Monday
                  </button>
                  <button
                    className="elite-program__button elite-program__button--days"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: e.target.innerText,
                        time: getTimeOfDay(),
                      })
                    }
                    style={{
                      background: day === "Tuesday" ? colorGreen : "",
                    }}
                  >
                    Tuesday
                  </button>
                  <button
                    className="elite-program__button elite-program__button--days"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: e.target.innerText,
                        time: getTimeOfDay(),
                      })
                    }
                    style={{
                      background: day === "Wednesday" ? colorGreen : "",
                    }}
                  >
                    Wednesday
                  </button>
                  <button
                    className="elite-program__button elite-program__button--days"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: e.target.innerText,
                        time: getTimeOfDay(),
                      })
                    }
                    style={{
                      background: day === "Thursday" ? colorGreen : "",
                    }}
                  >
                    Thursday
                  </button>
                  <button
                    className="elite-program__button elite-program__button--days"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: e.target.innerText,
                        time: getTimeOfDay(),
                      })
                    }
                    style={{
                      background: day === "Friday" ? colorGreen : "",
                    }}
                  >
                    Friday
                  </button>
                  <button
                    className="elite-program__button elite-program__button--days"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: e.target.innerText,
                        time: getTimeOfDay(),
                      })
                    }
                    style={{
                      background: day === "Saturday" ? colorGreen : "",
                    }}
                  >
                    Saturday
                  </button>
                  <button
                    className="elite-program__button elite-program__button--days"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: e.target.innerText,
                        time: getTimeOfDay(),
                      })
                    }
                    style={{
                      background: day === "Sunday" ? colorGreen : "",
                    }}
                  >
                    Sunday
                  </button>
                </div>

                <span className="elite-program__select-label">Select Time</span>

                <div className="elite-program__buttons elite-program__buttons--phases">
                  <button
                    className="elite-program__button elite-program__button--phases"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: day,
                        time: e.target.innerText,
                      })
                    }
                    style={{
                      background: time === "Morning" ? colorGreen : "",
                    }}
                  >
                    Morning
                  </button>
                  <button
                    className="elite-program__button elite-program__button--phases"
                    onClick={(e) =>
                      setSearchParams({
                        phase: phase,
                        day: day,
                        time: e.target.innerText,
                      })
                    }
                    style={{
                      background: time === "Afternoon" ? colorGreen : "",
                    }}
                  >
                    Afternoon
                  </button>
                </div>
              </>
            )}

            {data.map((a, index) => {
              return (
                <div key={index}>
                  {parseInt(a.phase) === parseInt(phase) ? (
                    <>
                      {a.days.map((b, index) => {
                        return b.day === day ? (
                          <div key={index} className="elite-program-card">
                            <div className="elite-program-card-header">
                              <div className="elite-program-card-header__day">
                                {b.day}
                              </div>

                              {b.times.map((c, index) => {
                                return (
                                  <div key={index}>
                                    {c.time === time ? (
                                      <>
                                        <div className="elite-program-card-heading__session-type">
                                          {c.session}
                                        </div>

                                        {c.exercise.length > 0 && (
                                          <div className="elite-program-card__detail">
                                            {c.exercise.map((d, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="elite-program-card__warm-up"
                                                >
                                                  <div className="elite-program-card__type-header">
                                                    <span className="elite-program-card__title">
                                                      {d.type}
                                                    </span>

                                                    {d.type === "Exercise" && (
                                                      <div className="elite-program-card__table-header">
                                                        <span className="elite-program-card__tag">
                                                          Reps
                                                        </span>
                                                        <span className="elite-program-card__tag">
                                                          Sets
                                                        </span>
                                                        <span className="elite-program-card__tag">
                                                          Rest
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>

                                                  {d.exercises.map(
                                                    (e, index) => {
                                                      return (
                                                        <div
                                                          key={index}
                                                          className="elite-program-card__exercise"
                                                        >
                                                          <span>
                                                            {e.exercise}
                                                          </span>

                                                          <span className="elite-program-card__action">
                                                            {e.comment}
                                                          </span>

                                                          {d.type ===
                                                            "Exercise" && (
                                                            <div className="elite-program-card__table-header">
                                                              <span className="elite-program-card__tag">
                                                                {e.reps}
                                                              </span>
                                                              <span className="elite-program-card__tag">
                                                                {e.sets}
                                                              </span>
                                                              <span className="elite-program-card__tag">
                                                                {e.interval}
                                                              </span>
                                                            </div>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </>
                                    ) : null}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null;
                      })}
                    </>
                  ) : null}
                </div>
              );
            })}
            <br />
          </InnerContent>
        </InnerContainer>
      </OutterContainer>
    </MainContainer>
  );
};

export default EliteProgram;
