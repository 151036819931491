import React from "react";
import "./Container.css";

export const MainContainer = ({ children }) => {
  return <div className="main-container">{children}</div>;
};

export const OutterContainer = ({ children }) => {
  return <div className="outter-container">{children}</div>;
};

export const InnerContainer = ({ children }) => {
  return <div className="inner-container">{children}</div>;
};

export const OutterContent = ({ children }) => {
  return <div className="outter-content">{children}</div>;
};

export const InnerContent = ({ children, className }) => {
  return <div className={`inner-content ${className}`}>{children}</div>;
};

export const OutterWrapper = ({ children }) => {
  return <div className="outter-wrapper">{children}</div>;
};

export const InnerWrapper = ({ children }) => {
  return <div className="inner-wrapper">{children}</div>;
};
