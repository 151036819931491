export const EliteData = [
  {
    phase: 1,
    days: [
      {
        day: "Monday",
        times: [
          {
            time: "Morning",
            session: "Weight Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Rowing", comment: "10 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Dumbbell Bench Press",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Incline Bench Press",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell single arm row",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Bent Over Row",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Tricep Kickback",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Sit Ups",
                    reps: "15+",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Bridging",
                    reps: "1 min",
                    sets: "2",
                    interval: "2 mins",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "10 mins" },
                  {
                    exercise: "Stretches",
                    comment:
                      "Chest, Triceps, Shoulder, Back, Quadriceps, Hamstrings, Calves",
                  },
                ],
              },
            ],
          },

          {
            time: "Afternoon",
            session: "Speed Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  { exercise: "High Knees", comment: "2 x 20m" },
                  { exercise: "Butt Kicks", comment: "2 x 20m" },
                  { exercise: "High Skips", comment: "2 x 20m" },
                  { exercise: "Ladder Runs", comment: "2 x 20m" },
                ],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "40m Straight Sprints",
                    reps: "3",
                    sets: "1",
                    interval: "3 mins",
                  },
                  {
                    exercise: "40m Change Direction Sprints",
                    reps: "3",
                    sets: "1",
                    interval: "3 mins",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  {
                    exercise: "Stretches",
                    comment: "Quadriceps, Hamstrings, Calves, Gluteals",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        day: "Tuesday",
        times: [
          {
            time: "Morning",
            session: "Take a Rest!",
            exercise: [],
          },
          {
            time: "Afternoon",
            session: "Speed Session",
            exercise: [
              {
                type: "30 Minute Run",
                exercises: [
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  { exercise: "Steady Run", comment: "20 mins" },
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  {
                    exercise: "Stretches",
                    comment: "Quadriceps, Hamstrings, Calves",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        day: "Wednesday",
        times: [
          {
            time: "Morning",
            session: "Weight Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Rowing", comment: "10 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Dumbbell Shoulder Press",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Lateral Dumbbell Raise",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Biceps Curl",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Barbell Squats",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Multi Directional Lunges",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Calf Raises",
                    reps: "15+",
                    sets: "3",
                    interval: "90 secs",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "10 mins" },
                  {
                    exercise: "Stretches",
                    comment:
                      "Chest, Triceps, Shoulder, Back, Quadriceps, Hamstrings, Calves",
                  },
                ],
              },
            ],
          },
          {
            time: "Afternoon",
            session: "Take a Rest!",
            exercise: [],
          },
        ],
      },

      {
        day: "Thursday",
        times: [
          {
            time: "Morning",
            session: "Speed Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Jogging", comment: "10 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Full Field Shuttles (200m)",
                    reps: "10",
                    sets: "1",
                    interval: "200m Jogging",
                    intensity: "90%",
                  },

                  {
                    exercise: "Intensity 90%",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "10 mins" },
                  {
                    exercise: "Stretches",
                    comment: "Quadriceps, Hamstrings, Calves, Gluteals",
                  },
                ],
              },
            ],
          },
          {
            time: "Afternoon",
            session: "Take a Rest!",
            exercise: [],
          },
        ],
      },

      {
        day: "Friday",
        times: [
          {
            time: "Morning",
            session: "Weight Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Rowing", comment: "10 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Dumbbell Bench Press",
                    reps: "8-12",
                    sets: "2",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell One Arm Row",
                    reps: "8-12",
                    sets: "2",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Triceps Kickback",
                    reps: "8-12",
                    sets: "2",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Shoulder Press",
                    reps: "8-12",
                    sets: "2",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Biceps Curl",
                    reps: "8-12",
                    sets: "2",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Barbell Squats",
                    reps: "8-12",
                    sets: "2",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Multi Directional Lunges",
                    reps: "8-12",
                    sets: "2",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Calf Raises",
                    reps: "8-12",
                    sets: "2",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Sit Ups",
                    reps: "25+",
                    sets: "2",
                    interval: "90 secs",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "10 mins" },
                  {
                    exercise: "Stretches",
                    comment:
                      "Chest, Triceps, Biceps, Shoulders, Back, Quadriceps, Hamstrings, Calves",
                  },
                ],
              },
            ],
          },
          {
            time: "Afternoon",
            session: "Take a Rest!",
            exercise: [],
          },
        ],
      },

      {
        day: "Saturday",
        times: [
          {
            time: "Morning",
            session: "Take a Rest!",
            exercise: [],
          },
          {
            time: "Afternoon",
            session: "Rugby Specific Circuit",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Jogging", comment: "5 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Short Shuttles (22m)",
                    reps: "5",
                    sets: "1",
                    interval: "0",
                  },
                  {
                    exercise: "Tackle Bag Lifts",
                    reps: "10",
                    sets: "1",
                    interval: "30 secs",
                  },
                  {
                    exercise: "Tackle Bag Shuttles (22m)",
                    reps: "5",
                    sets: "1",
                    interval: "1 min",
                  },
                  {
                    exercise: "Press Ups",
                    reps: "20",
                    sets: "1",
                    interval: "None",
                  },
                  {
                    exercise: "Sit Ups",
                    reps: "20",
                    sets: "1",
                    interval: "None",
                  },
                  {
                    exercise: "Zig Zag Cone Sprints (40m)",
                    reps: "5",
                    sets: "1",
                    interval: "30 secs",
                  },
                  {
                    exercise: "One on One Scrummaging",
                    reps: "30 secs",
                    sets: "5",
                    interval: "15 secs",
                  },
                  {
                    exercise: "Shuttle Runs (50m)",
                    reps: "5",
                    sets: "1",
                    interval: "None",
                  },
                  {
                    exercise: "Down and Ups",
                    reps: "20",
                    sets: "1",
                    interval: "30 secs",
                  },
                  {
                    exercise: "Burpees",
                    reps: "10",
                    sets: "1",
                    interval: "None",
                  },
                  {
                    exercise: "REPEAT ⬆️",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  {
                    exercise: "Stretches",
                    comment: "Quadriceps, Hamstrings, Calves, Gluteals",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        day: "Sunday",
        times: [
          {
            time: "Morning",
            session: "Take a Rest!",
            exercise: [],
          },
          {
            time: "Afternoon",
            session: "Take a Rest!",
            exercise: [],
          },
        ],
      },
    ],
  },

  {
    phase: 2,
    days: [
      {
        day: "Monday",
        times: [
          {
            time: "Morning",
            session: "Weight Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Rowing", comment: "10 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Dumbbell Bench Press",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Incline Bench Press",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell single arm row",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Bent Over Row",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Tricep Kickback",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Swiss Ball Sit Ups",
                    reps: "15+",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Bridging",
                    reps: "1 min",
                    sets: "2",
                    interval: "2 mins",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "10 mins" },
                  {
                    exercise: "Stretches",
                    comment:
                      "Chest, Triceps, Shoulder, Back, Quadriceps, Hamstrings, Calves",
                  },
                ],
              },
            ],
          },

          {
            time: "Afternoon",
            session: "Speed Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  { exercise: "High Knees", comment: "2 x 20m" },
                  { exercise: "Butt Kicks", comment: "2 x 20m" },
                  { exercise: "High Skips", comment: "2 x 20m" },
                  { exercise: "Ladder Runs", comment: "2 x 20m" },
                ],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "40m Straight Sprints",
                    reps: "3",
                    sets: "1",
                    interval: "3 mins",
                  },
                  {
                    exercise: "40m Change Direction Sprints",
                    reps: "3",
                    sets: "1",
                    interval: "3 mins",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  {
                    exercise: "Stretches",
                    comment: "Quadriceps, Hamstrings, Calves, Gluteals",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        day: "Tuesday",
        times: [
          {
            time: "Morning",
            session: "Take a Rest!",
            exercise: [],
          },
          {
            time: "Afternoon",
            session: "Endurance Session",
            exercise: [
              {
                type: "30 Minute Run",
                exercises: [
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  { exercise: "Steady Run", comment: "20 mins" },
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  {
                    exercise: "Stretches",
                    comment: "Quadriceps, Hamstrings, Calves",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        day: "Wednesday",
        times: [
          {
            time: "Morning",
            session: "Weight Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Rowing", comment: "10 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Dumbbell Shoulder Press",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Lateral Dumbbell Raise",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Dumbbell Biceps Curl",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Barbell Squats",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Multi Directional Lunges",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                  {
                    exercise: "Calf Raises",
                    reps: "8-12",
                    sets: "3",
                    interval: "90 secs",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "10 mins" },
                  {
                    exercise: "Stretches",
                    comment:
                      "Chest, Triceps, Shoulder, Back, Quadriceps, Hamstrings, Calves",
                  },
                ],
              },
            ],
          },
          {
            time: "Afternoon",
            session: "Take a Rest!",
            exercise: [],
          },
        ],
      },

      {
        day: "Thursday",
        times: [
          {
            time: "Morning",
            session: "Short Interval Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Jogging", comment: "10 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "22 to Goal Line Shuttles",
                    reps: "10",
                    sets: "4",
                    interval: "50m Jogging",
                    intensity: "90-95%",
                  },

                  {
                    exercise: "Intensity 90-95%",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "10 mins" },
                  {
                    exercise: "Stretches",
                    comment: "Quadriceps, Hamstrings, Calves, Gluteals",
                  },
                ],
              },
            ],
          },
          {
            time: "Afternoon",
            session: "Take a Rest!",
            exercise: [],
          },
        ],
      },

      {
        day: "Friday",
        times: [
          {
            time: "Morning",
            session: "Power Weight Session",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Rowing", comment: "10 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Barbell Bench Press",
                    reps: "2-4",
                    sets: "3",
                    interval: "3-4 mins",
                  },
                  {
                    exercise: "Barbell Squats",
                    reps: "2-4",
                    sets: "3",
                    interval: "3-4 mins",
                  },
                  {
                    exercise: "Barbell Deadlift",
                    reps: "2-4",
                    sets: "3",
                    interval: "3-4 mins",
                  },
                  {
                    exercise: "Dumbbell Calf Raises",
                    reps: "2-4",
                    sets: "2",
                    interval: "3-4 mins",
                  },
                  {
                    exercise: "Medicine Ball Sit Ups (& throw)",
                    reps: "15+",
                    sets: "2",
                    interval: "2 mins",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "10 mins" },
                  {
                    exercise: "Stretches",
                    comment:
                      "Chest, Triceps, Biceps, Shoulders, Back, Quadriceps, Hamstrings, Calves",
                  },
                ],
              },
            ],
          },
          {
            time: "Afternoon",
            session: "Take a Rest!",
            exercise: [],
          },
        ],
      },

      {
        day: "Saturday",
        times: [
          {
            time: "Morning",
            session: "Take a Rest!",
            exercise: [],
          },
          {
            time: "Afternoon",
            session: "Rugby Specific Circuit",
            exercise: [
              {
                type: "Warm Up",
                exercises: [{ exercise: "Easy Jogging", comment: "5 mins" }],
              },
              {
                type: "Exercise",
                exercises: [
                  {
                    exercise: "Short Shuttles (22m)",
                    reps: "5",
                    sets: "1",
                    interval: "0",
                  },
                  {
                    exercise: "Tackle Bag Lifts",
                    reps: "10",
                    sets: "1",
                    interval: "30 secs",
                  },
                  {
                    exercise: "Tackle Bag Shuttles (22m)",
                    reps: "5",
                    sets: "1",
                    interval: "1 min",
                  },
                  {
                    exercise: "Press Ups",
                    reps: "20",
                    sets: "1",
                    interval: "None",
                  },
                  {
                    exercise: "Sit Ups",
                    reps: "20",
                    sets: "1",
                    interval: "None",
                  },
                  {
                    exercise: "Zig Zag Cone Sprints (40m)",
                    reps: "5",
                    sets: "1",
                    interval: "30 secs",
                  },
                  {
                    exercise: "One on One Scrummaging",
                    reps: "30 secs",
                    sets: "5",
                    interval: "15 secs",
                  },
                  {
                    exercise: "Shuttle Runs (50m)",
                    reps: "5",
                    sets: "1",
                    interval: "None",
                  },
                  {
                    exercise: "Down and Ups",
                    reps: "20",
                    sets: "1",
                    interval: "30 secs",
                  },
                  {
                    exercise: "Burpees",
                    reps: "10",
                    sets: "1",
                    interval: "None",
                  },
                  {
                    exercise: "REPEAT ⬆️",
                  },
                ],
              },
              {
                type: "Cool Down",
                exercises: [
                  { exercise: "Easy Jogging", comment: "5 mins" },
                  {
                    exercise: "Stretches",
                    comment: "Quadriceps, Hamstrings, Calves, Gluteals",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        day: "Sunday",
        times: [
          {
            time: "Morning",
            session: "Take a Rest!",
            exercise: [],
          },
          {
            time: "Afternoon",
            session: "Take a Rest!",
            exercise: [],
          },
        ],
      },
    ],
  },
];
