import React from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import imgLogo from "../../../assets/images/logo64.png";

const Header = () => {
  const navigateTo = useNavigate();
  return (
    <header className="header">
      <nav className="navbar">
        <div className="header_logo__wrapper">
          <img src={imgLogo} alt="logo" />
        </div>

        <ul className="navbar-menu">
          <li className="navbar-menu__item" onClick={() => navigateTo("/")}>
            Home
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
